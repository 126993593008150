.cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cart-wrapper {
  padding: 0px 10px 0px 10px;
}

.cart-total-wrapper {
  display: flex;
  background-color: var(--highlight);
  box-shadow: 2px 3px 5px var(--secondary);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: center;
}

.cart-options-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}
.cart-option-button {
  background-color: var(--secondary);
  box-shadow: 2px 3px 5px var(--highlight);
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-family: "Dancing Script", cursive;
  font-size: 18px;
}

.cart-option-button:hover {
  background-color: rgb(19, 19, 19);
  color: var(--secondary);
  cursor: pointer;
}

.order-instructions-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
}
.order-instructions {
  background-color: var(--secondary);
  padding: 10px;
  border-radius: 10px;
  border: 2px dashed black;
  box-shadow: 0 0 10px var(--highlight);
  max-width: 500px;
  text-align: center;
  margin-bottom: 2rem;
}
.order-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.form-label {
  font-family: "Dancing Script", cursive;
}

.order-form-input {
  border: none;
  border-radius: 5px;
  box-shadow: 2px 3px 5px var(--highlight);
  font-size: 16px;
  padding: 5px;
  margin-bottom: 10px;
}

.in-message {
  width: 300px;
  height: 100px;
}

textarea {
  resize: none;
}

.cart-empty {
  font-family: "Dancing Script", cursive;
  text-align: center;
  margin-bottom: 10px;
}
