.product {
    border-radius: 10px;
    display: flex;
    height: 100%;
    background-color: var(--secondary);
    flex-direction: column;
    align-items: center;
    position: relative;
    box-shadow: 2px 3px 5px var(--highlight);
    padding: 10px;
    justify-content: space-between;
  }
  
  .product-image {
    margin-bottom: 10px;
    width: 100%;
    border-radius: 10px;
    vertical-align: middle;
  }
  
  .description {
    text-align: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .sub-description {
    text-align: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    color: #424242;
  }
  .product-price{
    text-align: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 40px;
    margin-top: 10px;
    color: #db3286;
  }
  
  .add-button {
    position: absolute;
    bottom: 0;
    background-color: var(--highlight);
    height: 40px;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .add-button:hover {
    background-color: #131313;
    color: var(--secondary);
    cursor: pointer;
  }