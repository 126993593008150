.App {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.title-wrapper {
  display: inline-block;
  background-color: var(--secondary);
  border-radius: 10px;
  padding: 20px;
  font-family: "Dancing Script", cursive;
  box-shadow: 2px 3px 5px var(--highlight);
  margin: 20px;
}

#line-break{
  white-space: pre-line;
}