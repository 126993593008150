.order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products {
  width: 100vw;
  padding: 0rem 2rem 2rem 2rem;
  height: auto;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  place-items: center;
}

.to-cart-button {
  background-color: var(--secondary);
  box-shadow: 2px 3px 5px var(--highlight);
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-family: "Dancing Script", cursive;
  font-size: 18px;
  margin-bottom: 2rem;
}

.to-cart-button:hover {
  background-color: rgb(19, 19, 19);
  color: var(--secondary);
  cursor: pointer;
}
