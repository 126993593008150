.logo {
  height: 40vmin;
  pointer-events: none;
}
.home-title-wrapper {
  padding: 10px;
  text-align: center;
  background-color: var(--secondary);
  box-shadow: 2px 3px 5px var(--highlight);
}
.home-title {
  font-family: "Dancing Script", cursive;
}

.home-news-wrapper {
  padding: 40px;
  text-align: center;
}

.home-news {
  background-color: var(--secondary);
  padding: 10px;
  border-radius: 10px;
  border: 2px dashed black;
  box-shadow: 0 0 10px var(--highlight);
}
