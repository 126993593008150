.active {
  background-color: var(--highlight);
}

li.link {
  transition: transform 250ms;
}

li.link:hover {
  transform: translateY(-2px);
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  font-family: "Dancing Script", cursive;
  background-color: var(--secondary);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 2px 3px 5px var(--highlight);
  z-index: 100;
}

nav .title {
  font-size: 2rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: black;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  color: black;
}

nav ul li a:not(.active):hover {
  background-color: var(--primary);
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 520px) {
  nav .menu {
    display: flex;
  }
  nav .title {
    font-size: 1.5rem;
  }
  nav {
    flex-direction: column;
    align-items: start;
  }
  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }
  nav ul.open {
    display: flex;
  }
  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
}
