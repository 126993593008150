a.social {
  margin: 0 0.35rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.email {
  color: black;
}

/* a.facebook {
  color: #4968ad;
} */

a.instagram {
  color: black;
}
