.slide-wrapper{
    padding:  10px 10px 20px 10px;
    display: flex;
    justify-content: center;
}

.slide-image {
  width: 100%;
  max-width: 750px;
  background-color: var(--secondary);
  box-shadow: 2px 3px 5px 3px var(--highlight);
  border-radius: 10px;;
  padding: 10px;
}




