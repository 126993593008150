footer {
  margin-top: auto;
  background-color: #ecc2c2;
  padding: 4px;
  text-align: center;
}

.email-link {
  text-decoration: none;
}
.contact-info {
  display: inline-flex;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
}

/* @media (max-width: 520px) {
  .contact-info {
    font-size: 12px;
  }
} */
