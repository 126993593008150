*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root{
  --primary: #f0e5e5;
  --secondary: #edd3d3;
  --highlight: #ecc2c2;
}
