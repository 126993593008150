.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-grid{
  display: grid;
  gap: 1.5em;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  padding: 0em 1.5em 1.5em 1.5em;
}
.img-wrapper {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--secondary);
  box-shadow: 2px 3px 5px var(--highlight);
}
.text-wrapper {
  background-color: var(--secondary);
  border: 2px dashed black;
  box-shadow: 0 0 10px var(--highlight);
  border-radius: 10px;
  padding: 10px;
  max-width: 600px;
}
.about-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  vertical-align: middle;
}

.about-name {
  text-align: center;
  font-family: "Dancing Script", cursive;
}
.about-body {
  font-size: 18px;
  font-family: "Times New Roman", Georgia, Serif;
  text-align: center;
}

@media (max-width: 1000px) {
  .about-grid{
    grid-template-columns: 1fr;
  }
}