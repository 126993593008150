.order-list-item {
  background-color: var(--secondary);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 3px 5px var(--highlight);
  margin: 0px 10px 10px 10px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.amount-calc{
    display: inline-flex;
    align-items: center;
    position: absolute;
    right:0;
    height: 100%;
    padding-right: 10px;
}
.price{
    padding-right: 10px;
}

.amount {
  padding: 5px;
}

.incr-button {
  width: 1.5rem;
  height: 100%;
  border: none;
  background-color: var(--highlight);
  font-size: 20px;
}

.incr-button:hover {
  background-color: rgb(19, 19, 19);
  color: var(--secondary);
  cursor: pointer;
}
